var inputs = document.querySelectorAll('#category-form input');
var form = document.getElementById('category-form');
import g from './globals'

if (g.elementInDom('#category-form')) {
    const url = new URL(window.location.href)
    const directoryList = document.getElementById('business-directory')

    let getCategories = url.searchParams.get('category')

    if (getCategories != null || getCategories != undefined) {

        getCategories = url.searchParams.get('category').split(',')

        inputs.forEach(function(obj, index) {
            getCategories.forEach((cat) => {
                if (obj.value == cat) {
                    obj.checked = true
                }
            })
        })

        scrollToList()
    }

    if (g.elementInDom('.category-venues') || g.elementInDom('.category-rentals')) {
        scrollToList()
    }

    function scrollToList() {
        setTimeout(() => {
            // Scroll list into view
           directoryList.scrollIntoView({ behavior: "smooth"})
       }, 500)
    }

    form.addEventListener('submit', function(e) {
        e.preventDefault();
        let categories = [],
            category_names = []

        new Promise((resolve, reject) => {
            inputs.forEach(function(obj, index) {
                if (obj.checked) {
                    var urlSlug = obj.value,
                        category_name = obj.dataset.category;
    
                    categories.push(urlSlug)
                    category_names.push(category_name + " ")
                }
            })
            resolve()
        }).then(() => {
            window.location = '/cstreet/directory/?category=' + categories + '&cat_name=' + category_names;
            return
        }).catch((error) => {
            console.log(error)
        })
    })
}