  // import Swiper JS
  import Swiper, { Navigation } from 'swiper'
  import 'swiper/swiper-bundle.css'
  import g from './globals'

  Swiper.use([Navigation]);
  
  
  if (g.elementInDom('.content-slider')) {
      var sliders = document.querySelectorAll('.content-slider')
  
      sliders.forEach((obj, index) => {
          new Swiper(obj.querySelector('.slider-container'), {
              direction: 'horizontal',
              loop: true,
              autoplay: {
                  delay: 2000,
              },
              navigation: {
                  nextEl: '.navigation .next',
                  prevEl: '.navigation .prev',
              },
          })
      })
  }
  