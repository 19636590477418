import g from './globals'


if (g.elementInDom('.sustainability')) {
    var descriptions = document.querySelectorAll('.description-item'),
        bottle = document.querySelector('.bottle-wrapper')

    descriptions.forEach((obj, index)=> {
        obj.querySelector('.toggle').addEventListener('click', (e) => {
            e.preventDefault();

            bottle.classList.add('animTilt')

            new Promise((resolve, reject) => {
                hideAllDescriptions(resolve)
            })
            .then(() => {
                obj.classList.add('description-active')
                setTimeout(() => {
                    bottle.classList.remove('animTilt')
                }, 1000)
            })
            .catch((error) => {
                console.log(error)
            })
        })
    })

    function hideAllDescriptions(resolve) {

        descriptions.forEach((obj, index) => {
            obj.classList.remove('description-active')
        })
        resolve()
    }
}