import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import g from './globals'

gsap.registerPlugin(ScrollTrigger)

if (g.elementInDom('.hero')) {
    const hero = document.querySelectorAll('.hero')

    hero.forEach((obj, index) => {
        const arrow = obj.querySelector('.arrow-down')
        var modules = document.querySelectorAll(".module");
        var moduleIndex = [].indexOf.call(modules, obj);
        const nextModule = modules[moduleIndex += 1]

        console.log(moduleIndex)

        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=200% top',
                end:  '+=300%',
                scrub: true,
            }
        })
        
        tl.fromTo(obj.querySelector('.background'),
            {scale: 1.3},
            {scale: 1, duration: 1000}
        )

        // Nav Arrow
        arrow.addEventListener('click', (e) => {
            e.preventDefault()
            nextModule.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            })
        })
    })
}