import g from './globals'

if (g.elementInDom('.tag-show-month-only')) {
    const monthOnly = document.querySelectorAll('.tag-show-month-only')

    monthOnly.forEach((obj, index) => {
        let monthHTML = obj.querySelector('.tribe-event-date-start')
        let pattern = /^(\w+)\s*(.*)$/;

        monthHTML.innerHTML = monthHTML.innerHTML.replace(pattern, "$1") + ' TBA';
    })
}

// Hack the events calendar to remove month only product markers
// if (g.elementInDom('.tribe-events-calendar-month-mobile-events__mobile-day')) {
//     const calParent = document.querySelectorAll('.tribe-events-calendar-month-mobile-events__mobile-day')

//     calParent.forEach((obj, index) => {
//         let event_date = obj.id.slice(-10)
//         console.log(event_date)

//         new Promise((resolve, reject) => {
//             if (obj.querySelector('.tribe-events-calendar-month-mobile-events__mobile-event')) resolve()
//         }).then(() => {
//             if (obj.querySelector('.tribe-events-calendar-month-mobile-events__mobile-event').classList.contains('tag-show-month-only')) {
//                 document.querySelector('button[aria-controls="tribe-events-calendar-mobile-day-' + event_date + '"]').style.display = 'none !important'
//                 document.querySelector('#tribe-events-calendar-mobile-day-' + event_date + ' time').innerHTML = 'TBA'
//                 document.querySelector('#tribe-events-calendar-mobile-day-' + event_date + ' article time').style.display = 'none !important'
//             }
//         }).catch((error) => {
//             console.log(error)
//         })
//     })
// }