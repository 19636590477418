import globals from './globals'
import anim from './animations'

var mobile = window.matchMedia( "(max-width: 1023px)" )

document.addEventListener("DOMContentLoaded", function () {
    var subMenuItems = document.querySelectorAll('.sub-menu .menu-item'),
        menuHasChildren = document.querySelectorAll('.menu-item-has-children')

    subMenuItems.forEach((obj, index) => {
        obj.querySelector('a').classList.add('sub-anchor')
    })


})