import g from './globals'

if (g.elementInDom('.category-search')) {
    const categoryForm = document.querySelector('.category-form')
    const selectToggle = document.querySelector('.select-toggle')
    

    selectToggle.addEventListener('click', (e) => {
        e.preventDefault()
        categoryForm.classList.toggle('form-active')
    })
}