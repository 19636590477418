import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import g from './globals'

gsap.registerPlugin(ScrollTrigger)

if (g.elementInDom('.float-image-container')) {
    const images = document.querySelectorAll('.float-image-container')

    images.forEach((obj, index) => {
        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=150% top',
                end:  '+=150%',
                scrub: true,
            }
        
        })
        
        tl.fromTo(obj.querySelector('.float-image'),
            {top: '0%'},
            {top: '40%', duration: 500}
        )
    })

    images.forEach((obj, index) => {
        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=130% top',
                end:  '+=150%',
                scrub: true,
            }
        
        })
        
        tl.fromTo(obj.querySelector('.float-image-reverse'),
            {top: '-40%'},
            {top: '20%', duration: 500}
        )
    })

    images.forEach((obj, index) => {
        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=150% top',
                end:  '+=150%',
                scrub: true,
            }
        
        })
        
        tl.fromTo(obj.querySelector('.float-image-bottom'),
            {top: '20%'},
            {top: '-40%', duration: 500}
        )
    })
}