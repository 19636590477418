document.addEventListener("DOMContentLoaded", function () {
    const btn_triggers = document.querySelectorAll('#menu-main .menu-item.mega-menu-parent'),
          menu_items = document.querySelectorAll('#menu-main .menu-item'),
          mega_menus = document.querySelectorAll('.mega-menu')

    let shopMenuOpen = false

    btn_triggers.forEach((obj, index) => {
        obj.addEventListener('mouseover', (e) => {
            e.preventDefault();

            new Promise((resolve, reject) => {
                obj.classList.remove('mega-menu-trigger-active')
                hideMegaMenus(resolve)
            })
            .then(() => {
                shopMenuOpen = true
                obj.classList.add('mega-menu-trigger-active')
                mega_menus[index].classList.add('mega-menu-open')
            })
            .catch((error) => {
                console.log(error)
            })
        })

        menu_items.forEach((o, i) => {
            if (!o.classList.contains('mega-menu-parent')) {
                o.addEventListener('mouseover', (e) => {
                    hideMegaMenus(function(){})
                    obj.classList.remove('mega-menu-trigger-active')
                })
            }
        })

        mega_menus[index].addEventListener('mouseleave', (e) => {
            e.preventDefault()
            mega_menus[index].classList.remove('mega-menu-open')
            obj.classList.remove('mega-menu-trigger-active')
        })
    })
    

    function hideMegaMenus(resolve) {
        mega_menus.forEach((obj, index) => {
            obj.classList.remove('mega-menu-open')
        })
        resolve()
    }
 })