import g from './globals'

if (g.elementInDom('.events-archive')) {
    const calendar = document.querySelector('.calendar-container')

    calendar.querySelector('.toggle-trigger').addEventListener('click', (e) => {
        e.preventDefault()

        calendar.classList.toggle('calendar-active')
    })
}