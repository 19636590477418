import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import g from './globals'

Swiper.use([Navigation, Autoplay]);

if (g.elementInDom('.slider')) {
    var sliders = document.querySelectorAll('.slider')

    sliders.forEach((obj, index) => {
        new Swiper(obj.querySelector('.slider-container'), {
            direction: 'horizontal',
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        
            // If we need pagination
            navigation: {
                nextEl: '.next',
                prevEl: '.prev',
            },
        })
    })
}
